import styles from "./accordionItem.module.scss";
interface Props {
    title: string;
    body: string;
    isOpen: boolean;
}

const AccordionItem: React.FC<Props> = ({ title, body, isOpen }) => {
    return (
        <div className={`flex flex-col ${styles["border-bottom"]}`}>
            <div className="flex p-2 cursor-pointer items-center">
                <span
                    className={`rounded-md ${styles.border} bg-white w-8 h-8 flex items-center justify-center mr-8`}
                >
                    <img
                        className="w-3 h-3"
                        src="/assets/question-mark.svg"
                        alt=""
                    />
                </span>
                <p className={`text-base text-left ${styles.question}`}>
                    {title}
                </p>
                <img
                    className={`${
                        isOpen ? "rotate-90" : ""
                    } w-4 h-4 ml-auto transform transition-transform`}
                    src="/assets/arrow.svg"
                    alt=""
                />
            </div>
            <div
                className={`${styles.answer} ${
                    !isOpen ? "h-0 text-transparent" : "p-3"
                } text-sm `}
            >
                <p>{body}</p>
            </div>
        </div>
    );
};
export default AccordionItem;

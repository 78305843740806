import { ReactElement, useState } from "react";
import AccordionItem from "../../shared/accordionItem";
import styles from "./faq.module.scss";
export default function FAQ(): ReactElement {
    const [activeTab, setActiveTab] = useState(-1);

    const clickHandler = (idx: number) => {
        if (idx === activeTab) {
            setActiveTab(-1);
            return;
        }
        setActiveTab(idx);
    };

    return (
        <div className={`${styles["antivirus-gray-bg"]}`}>
            <section className={`pt-12 px-4 py-2 text-left text-base`}>
                <div className="md:flex  w-full flex-column mb-8">
                    <div className="md:w-6/12 md:pr-6 mb-6">
                        <h1 className="text-xl font-bold mb-4">
                            What is an antivirus?
                        </h1>
                        <p>
                            An antivirus is a software program designed to
                            detect and eliminate malware from computers and
                            other devices. Antivirus solutions use machine
                            learning, technical analysis, and AI to detect and
                            remove any cybersecurity threats. Each antivirus
                            solution listed above was thoroughly tested to
                            ensure top-notch service quality, delivering
                            real-time protection, online security, and
                            comprehensive device security.
                        </p>
                    </div>
                    <div className="md:w-6/12 md:pl-6 ">
                        <h1 className="text-xl font-bold mb-4">
                            Why do I need an antivirus?
                        </h1>
                        <p>
                            In our interconnected world, where sensitive
                            information is often stored online, cyber crimes
                            have become some of the most prevalent offenses
                            globally. Identity theft, fraud, and data breaches
                            are more prevalent than ever, posing serious threats
                            that require immediate remedy. Antiviruses emerge as
                            an effective solution, acting as a shield against
                            the most modern form of criminal activity.
                        </p>
                    </div>
                </div>
                <div className={`mb-8 pb-16 ${styles.border}`}>
                    <h1 className="text-xl font-bold mb-4">Disclaimer</h1>
                    <div>
                        At <b>AntivirusChoice</b>, we keep a close eye on the
                        latest deals to ensure you get the best prices
                        available.
                        <br /> Our reviews remain unbiased, providing you with
                        the honest opinions of cybersecurity experts and
                        professionals. While we may earn commissions from
                        referral links, we are not sponsored. Our commitment is
                        to offer transparent and reliable information,
                        empowering you to make well-informed choices.
                    </div>
                </div>
            </section>
            <section className="pt-2 flex text-lg md:text-left md:text-sm px-4">
                <div
                    className={`${styles.border} flex flex-col lg:flex-row pb-16 w-full`}
                >
                    <div className="lg:w-6/12 w-full lg:pr-6">
                        <h1 className="text-3xl font-bold mb-8">
                            Frequently Asked Questions
                        </h1>
                        {questions.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onClick={() => clickHandler(idx)}
                                >
                                    <AccordionItem
                                        title={item.title}
                                        body={item.body}
                                        isOpen={idx === activeTab}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="lg:w-6/12 pt-10 lg:pt-16 hidden md:flex flex-col lg:pl-6">
                        <br />
                        <p>
                            IIf you're feeling overwhelmed by the rapid pace of
                            change in the world, brace yourself; it's only going
                            to get faster. The arrival of new technologies is
                            speeding up, reshaping how we work, think, and live.
                            At <strong>AntivirusChoice</strong>, we are taking
                            this fast ride with you, shoulder by shoulder-, to
                            ensure that your cybersecurity is in safe hands. Let
                            us handle the heavy lifting when it comes to
                            selecting your next antivirus solution. We've
                            thoroughly tested, examined, and reviewed the top
                            antivirus options to guide you through all the ins
                            and outs of each one.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}
const questions = [
    {
        title: "Can an antivirus solution really protect me against spyware, ransomware, and other malware?",
        body: "Each antivirus solution listed above has been thoroughly tested and proven to be an effective shield against spyware, ransomware, and all forms of malware.",
    },
    {
        title: "Should I use free antivirus software?",
        body: "Yes, but not all of them. Some free antivirus solutions provide either fake or very limited protection. Typically, well-known antivirus brands offer free versions with restricted protection and security features to encourage users to upgrade to their premium solutions.",
    },
    {
        title: "Which antivirus is best for mobile?",
        body: "All the antivirus solutions listed above offer comprehensive mobile applications that include many features found in the PC versions.",
    },
];
